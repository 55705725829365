import axios from "axios";
import { BASE_ENDPOINT } from "./urls";
import {
    IndividualPortfolioResponse,
    PortfolioCreationPayload,
    PortfolioCreationResponse,
    PortfolioDeleteResponse,
    UserPortfoliosResponse,
} from "./api";

const userPortfoliosEndpoint = "/portfolio";

export const getUserPortfolios = async (): Promise<UserPortfoliosResponse> => {
    const response = await axios.get(
        `${BASE_ENDPOINT}${userPortfoliosEndpoint}`
    );
    return response.data;
};

export const getPortfolio = async (
    portfolioId: string
): Promise<IndividualPortfolioResponse> => {
    const endpoint = `${BASE_ENDPOINT}${userPortfoliosEndpoint}/portfolio_id/${portfolioId}`;
    const response = await axios.get(endpoint);
    return response.data;
};

export const createPortfolio = async (
    payload: PortfolioCreationPayload
): Promise<PortfolioCreationResponse> => {
    const endpoint = `${BASE_ENDPOINT}${userPortfoliosEndpoint}`;
    const response = await axios.post(endpoint, payload);
    return response.data;
};

export const deletePortfolio = async (
    portfolioId: string
): Promise<PortfolioDeleteResponse> => {
    const endpoint = `${BASE_ENDPOINT}${userPortfoliosEndpoint}/portfolio_id/${portfolioId}`;
    const response = await axios.delete(endpoint);
    return response.data;
};

export const getAllAvailableStrategies = async () => {
    const endpoint = `${BASE_ENDPOINT}${userPortfoliosEndpoint}/available_strategies`;
    const response = await axios.get(endpoint);
    return response.data;
};

export const addAssetToPortfolio = async (
    portfolioId: string,
    payload: any
) => {
    const endpoint = `${BASE_ENDPOINT}${userPortfoliosEndpoint}/portfolio_id/${portfolioId}`;
    const response = await axios.post(endpoint, payload);
    return response.data;
};

export const deleteAssetFromPortfolio = async (
    ticker: string,
    portfolioId: string
) => {
    const endpoint = `${BASE_ENDPOINT}${userPortfoliosEndpoint}/portfolio_id/${portfolioId}/${ticker}`;
    const response = await axios.delete(endpoint);
    return response.data;
};
