import { StockData } from "./api/api";

export const roundNumberToTwoDecimalPlaces = (num: number): number => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const commaSeparatedNumber = (num: number): string => {
    num = roundNumberToTwoDecimalPlaces(num);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumber = (number: number): string => {
    const suffixes = ["", "K", "M", "B"]; // Add 'T' for trillions if needed
    let absNumber = Math.abs(number);

    if (absNumber === 0) {
        return "0";
    }

    // Find the appropriate suffix based on magnitude
    let i = 0;
    while (absNumber >= 1000 && i < suffixes.length - 1) {
        absNumber /= 1000;
        i += 1;
    }

    // Format with one decimal place (if not zero) and suffix
    const formattedNumber = `${absNumber.toFixed(absNumber >= 1 ? 1 : 0)}`;
    return number >= 0
        ? `${formattedNumber.slice(0, -2)}${formattedNumber.slice(-2)}${
              suffixes[i]
          }`
        : `-${formattedNumber.slice(0, -2)}${formattedNumber.slice(-2)}${
              suffixes[i]
          }`;
};

export const calculateVolatility = (
    prices: StockData[],
    days: number = 30
): number => {
    if (days > prices.length) days = prices.length;

    const prices30 = prices.slice(-days);
    const returns = prices30.map((price, index) => {
        if (index === 0) {
            return 0;
        } else {
            const prevPrice = prices30[index - 1].close;
            return (price.close - prevPrice) / prevPrice;
        }
    });
    const squaredReturns = returns.map((returnVal) => returnVal ** 2);
    const volatility = Math.sqrt(
        squaredReturns.reduce((sum, val) => sum + val, 0) / days
    );
    return volatility * 100;
};

export const getLargestStringNumber = (arr: string[]): string => {
    const numbers = arr.map((str) => parseFloat(str));
    const indexOfLargest = numbers.indexOf(Math.max(...numbers));
    return arr[indexOfLargest];
};

export const convertCamelCaseToTitleCase = (str: string): string => {
    return str
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (char) => char.toUpperCase());
};

export const formatReasonString = (reason: string | undefined) => {
    if (!reason) return <div></div>;
    return reason.split("+").map((r) => {
        let normalString = convertToNormalString(r);
        return <li key={normalString}>{normalString}</li>;
    });
};

export const convertToNormalString = (input: string) => {
    let result = input.replace(/_/g, " ");

    result = result.charAt(0).toUpperCase() + result.slice(1);

    return result;
};

export const convertDateToStringFormat = (date: Date): string => {
    return date.toISOString().split("T")[0];
};
