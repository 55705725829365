import axios from "axios";
import { BASE_ENDPOINT } from "./urls";
import {
    StockDataResponse,
    StockData,
    Strategies,
    StrategiesTransaction,
} from "./api";

const stock = "/asset_gather/stock";

const stock_data = "/asset_gather/stock/data";

const strategyies = "/strategies/strategy";
axios.interceptors.request.use((config) => {
    const token = sessionStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export const getStockData = async (
    asset: string
): Promise<StockDataResponse> => {
    const response = await axios.get(`${BASE_ENDPOINT}${stock_data}/${asset}`);
    return response.data;
};

export const getStrategyDataUsingCustomDataset = async (
    historical_data: StockData[],
    strategy: string,
    assetId: string
): Promise<StrategiesTransaction[]> => {
    const response = await axios.post(
        `${BASE_ENDPOINT}${strategyies}/${assetId}`,
        {
            historical_data,
            strategy,
        }
    );
    return response.data;
};
