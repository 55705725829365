import React, { createContext, useContext, useState, useEffect } from "react";
import { getStockData } from "../api/stock";
import { convertDateStringtoDateObject } from "../chartApp/utils";
import { StockData, StockInfo, Strategies } from "../api/api";
import { AssetTechnicalData, FundamentalData } from "./assetData";

import { SMA } from "../calculators/technicalIndicators";

interface AssetContextType {
    asset: string;
    assetData: StockData[];
    loaded: boolean;
    error: string;
    assetInfo: StockInfo;
    pending: boolean;
    technicalData: AssetTechnicalData;
    fundamentalData: FundamentalData;
    strategyData: Strategies;
    updateAsset: (asset: string) => void;
    updateAssetData: (data: StockData[]) => void;
    setLoaded: (status: boolean) => void;
    setError: (errorMsg: string) => void;
}

const AssetContext = createContext<AssetContextType>({
    asset: "",
    assetData: [],
    loaded: false,
    error: "",
    strategyData: {} as Strategies,
    assetInfo: {} as StockInfo,
    pending: false,
    technicalData: {} as AssetTechnicalData,
    fundamentalData: {} as FundamentalData,
    updateAsset: () => {},
    updateAssetData: (data: StockData[]) => {},
    setLoaded: (status: boolean) => {},
    setError: (errMsg: string) => {},
});

export const AssetProvider = ({ children }: any) => {
    const [asset, setAsset] = useState("");
    const [assetData, setAssetData] = useState<StockData[]>([]);
    const [assetInfo, setAssetInfo] = useState<StockInfo>({} as StockInfo);
    const [technicalData, setTechnicalData] = useState<AssetTechnicalData>(
        {} as AssetTechnicalData
    );
    const [fundamentalData, setFundamentalData] = useState<FundamentalData>(
        {} as FundamentalData
    );
    const [strategyData, setStrategyData] = useState<Strategies>(
        {} as Strategies
    );

    const [pending, setPending] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState("");
    const updateAsset = (asset: string) => {
        setAsset(asset.toUpperCase());
        retrieveAllDataFor(asset.toUpperCase());
    };

    const updateAssetData = (data: StockData[]) => {
        setAssetData(data);
    };

    const retrieveAllDataFor = (asset: string) => {
        setPending(true);
        getStockData(asset)
            .then((data) => {
                if (data.data) {
                    setError("");
                    data.data.forEach((d: any) => {
                        d.dateString = d.date;
                        d.date = convertDateStringtoDateObject(d.date);
                    });
                    setTechnicalData({
                        "50": { sma: SMA(data.data, 50), color: "red" },
                        "100": { sma: SMA(data.data, 100), color: "orange" },
                        "200": { sma: SMA(data.data, 200), color: "yellow" },
                    });
                    updateAssetData(data.data);
                }

                if (data.info) {
                    setAssetInfo(data.info);
                }

                if (data.balancesheet) {
                    setFundamentalData({
                        balancesheet: data.balancesheet,
                        earnings: data.earnings,
                    });
                }

                if (data.strategies) {
                    for (const strategy in data.strategies) {
                        data.strategies[strategy].previous_transactions.forEach(
                            (d: any) => {
                                d.dateString = d.date;
                                d.date = convertDateStringtoDateObject(d.date);
                            }
                        );
                    }
                    setStrategyData(data.strategies);
                }
            })
            .catch((error: any) => {
                setError("We will be back to fixing this issue");
            })
            .finally(() => {
                setLoaded(true);
                setPending(false);
            });
    };

    return (
        <AssetContext.Provider
            value={{
                asset,
                assetData,
                assetInfo,
                loaded,
                pending,
                strategyData,
                error,
                technicalData,
                fundamentalData,
                updateAsset,
                updateAssetData,
                setLoaded,
                setError,
            }}
        >
            {children}
        </AssetContext.Provider>
    );
};

export const useAsset = () => useContext(AssetContext);
