import React, { createContext, useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

interface AuthContextType {
    authenticated: boolean;
    token: string;
    login: (token: string) => void;
    logout: () => void;
}

// Create a context for managing authentication state
const AuthContext = createContext<AuthContextType>({
    authenticated: false,
    token: "",
    login: () => {},
    logout: () => {},
});

// AuthProvider component to manage authentication state
export const AuthProvider = ({ children }: any) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [token, setToken] = useState("");

    const login = (token: string) => {
        setAuthenticated(true);
        sessionStorage.setItem("token", token);
        setToken(token);
    };

    // Method to unset authenticated status (logout)
    const logout = () => {
        setAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ authenticated, token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to access authentication state and methods
export const useAuth = () => useContext(AuthContext);
