import React, { FC, useEffect, useState } from "react";

import { useAsset } from "../hooks/AssetContext";
import { SMA } from "../calculators/technicalIndicators";
import { Table } from "antd";

interface SimpleMovingAveragesInterface {
    movingAverage: number | undefined;
    key: number;
    difference: number;
}

const MovingAveragesTable: FC = () => {
    const { assetData } = useAsset();
    const [simpleMovingAverages, setSimpleMovingAverages] = useState<
        SimpleMovingAveragesInterface[]
    >([]);

    useEffect(() => {
        if (assetData.length > 0) {
            let lastPrice = assetData.slice(-1)[0].close;
            const sma20 = SMA(assetData, 20);
            const sma50 = SMA(assetData, 50);
            const sma200 = SMA(assetData, 200);
            let tmpSma = [];
            tmpSma.push(
                {
                    movingAverage: sma20.slice(-1)[0],
                    key: 20,
                    difference: lastPrice - (sma20.slice(-1)[0] ?? 0),
                },
                {
                    movingAverage: sma50.slice(-1)[0],
                    key: 50,
                    difference: lastPrice - (sma50.slice(-1)[0] ?? 0),
                },
                {
                    movingAverage: sma200.slice(-1)[0],
                    key: 200,
                    difference: lastPrice - (sma200.slice(-1)[0] ?? 0),
                }
            );
            setSimpleMovingAverages(tmpSma);
        }
    }, [assetData]);

    const columns = [
        {
            title: "Period",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "Moving Average",
            dataIndex: "movingAverage",
            key: "movingAverage",
            render: (value: any) => parseFloat(value).toFixed(2),
        },
        {
            title: "Difference",
            dataIndex: "difference",
            key: "difference",
            render: (value: any) => parseFloat(value).toFixed(2),
        },
    ];

    return (
        <div>
            <h1>Moving Averages</h1>
            <Table dataSource={simpleMovingAverages} columns={columns} />
        </div>
    );
};

export default MovingAveragesTable;
