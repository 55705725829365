import React, { FC } from "react";

import MovingAveragesTable from "./MovingAveragesTable";
import StochasticsTable from "./StochasticsTable";
const TechnicalAnalysisApp: FC = () => {
    return (
        <div>
            <MovingAveragesTable />
            <StochasticsTable />
        </div>
    );
};

export default TechnicalAnalysisApp;
