import React, { useEffect } from "react";
import { Layout } from "antd";
import { useParams } from "react-router-dom";

import { SearchModal } from "./SearchModal";
import { items } from "./AppItems";
import { useAsset } from "../hooks/AssetContext";
import { AssetInfo } from "./AssetInfo";
import SearchInput from "./SearchInput";
import PortfolioApp from "../PortfoliosApp/PortfolioApp";
const { Content } = Layout;

interface ContentContainer {
    borderRadiusLG: number;
    isSearchModalVisible: boolean;
    onSearchButtonClick: () => void;
    selectedApp: number;
}

export const ContentContainer: React.FC<ContentContainer> = ({
    borderRadiusLG,
    isSearchModalVisible,
    onSearchButtonClick,
    selectedApp,
}) => {
    const { assetid, portfolioid } = useParams();

    const showSearchInput = !assetid && !portfolioid && selectedApp !== 7;

    return (
        <div
            style={{
                padding: 24,
                backgroundColor: "white",
            }}
        >
            <SearchModal
                visible={isSearchModalVisible}
                onClose={onSearchButtonClick}
            />
            {showSearchInput && (
                <>
                    <SearchInput />
                </>
            )}
            {assetid && <AssetInfo />}
            {portfolioid && selectedApp === 7 && <PortfolioApp />}
            <div>{items.find((item) => item.key === selectedApp)?.element}</div>
        </div>
    );
};
