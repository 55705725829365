import React, { useEffect, useState } from "react";
import { Form, Input, Button, InputNumber, Row, Statistic, Col } from "antd";
import DiscountedCashFlow, {
    DiscountedCashFlowState,
} from "../calculators/dcf";
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Area,
} from "recharts";

import _ from "lodash";
import { formatDCFDataForChart, formatNumber } from "./utils";

interface DCFCalculatorApp {
    freeCashFlow: number;
    price: number;
}
const DCFCalculatorApp: React.FC<DCFCalculatorApp> = ({
    freeCashFlow,
    price,
}) => {
    const [dcfDetails, setDcfDetails] = useState<DiscountedCashFlowState>(
        {} as DiscountedCashFlowState
    );
    const [growthRate, setGrowthRate] = useState<number>(20);
    const [terminalFcf, setTerminalFcf] = useState<number>(
        price / freeCashFlow
    );
    const [discountRate, setDiscountRate] = useState(6);
    const [chartDcfData, setChartDcfData] = useState<any[]>([]);

    useEffect(() => {
        if (freeCashFlow) {
            let dcfCalculation = DiscountedCashFlow.calculate(
                (freeCashFlow = freeCashFlow!),
                growthRate / 100,
                terminalFcf,
                discountRate / 100,
                2
            );
            let chartDcfData = formatDCFDataForChart(dcfCalculation);
            console.log(chartDcfData);
            setChartDcfData(chartDcfData);
            setDcfDetails(dcfCalculation);
        }
    }, [freeCashFlow, growthRate, terminalFcf, discountRate]);

    const onDiscountChange = (value: any) => {
        let changedValue = parseFloat(value);
        if (isNaN(changedValue)) {
            setDiscountRate(0);
        }
        setDiscountRate(changedValue);
    };

    const onGrowthRateChange = (event: any) => {
        let growthRates = event.target.value;
        setGrowthRate(growthRates);
    };

    const onTerminalFcfChange = (event: any) => {
        let changedValue = parseFloat(event.target.value);
        setTerminalFcf(changedValue);
    };

    return (
        <Row
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Form>
                <Form.Item label="Free Cash Flow">
                    <Input value={_.round(freeCashFlow, 4)} disabled />
                </Form.Item>
                <Form.Item label="Discount Rate (%)">
                    <InputNumber
                        onChange={onDiscountChange}
                        value={discountRate}
                    />
                </Form.Item>
                <Form.Item label="Terminal Free Cash Flow Multiple (PFcf)">
                    <Input
                        onChange={onTerminalFcfChange}
                        value={_.round(terminalFcf, 2)}
                    />
                </Form.Item>
                <Form.Item label="Growth Rate (%)">
                    <Input value={growthRate} onChange={onGrowthRateChange} />
                </Form.Item>
            </Form>
            <ComposedChart
                width={500}
                height={400}
                data={chartDcfData}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <XAxis
                    dataKey="index"
                    tickFormatter={(value) => `Year ${value}`}
                />
                <YAxis
                    type="number"
                    tickFormatter={(value) => `${formatNumber(value)}`}
                />
                <Legend />
                <Bar
                    name="Future Cash Flows"
                    dataKey="futureCashFlows"
                    barSize={20}
                    fill="#green"
                    // stackId={"asset"}
                />
                <Bar
                    name="Present Value Cash Flows"
                    dataKey="presentValueFutureCashFlows"
                    barSize={20}
                    fill="#413ea0"
                    // stackId={"asset"}
                />
            </ComposedChart>
            <div>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Statistic
                            title="Total Present Value"
                            value={`$${dcfDetails?.totalPresentValue}`}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Present Value Future Sales"
                            value={`$${dcfDetails?.presentValueFutureSale}`}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Margin of Safety"
                            value={`${_.round(
                                (1 - price / dcfDetails.totalPresentValue) *
                                    100,
                                2
                            )}%`}
                        />
                    </Col>
                </Row>
            </div>
        </Row>
    );
};

export default DCFCalculatorApp;
