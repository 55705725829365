import { PriceChart } from "../chartApp/StockHistoricalDataChart";
import FundamentalApp from "../fundamentalApp/FundamentalApp";
import TechnicalAnalysisApp from "../technicalAnalysisApp/TechnicalAnalysisApp";
import MacroApp from "../macrosApp/MacroApp";
import ModelApp from "../modelApp/ModelApp";
import PortfolioDashboardApp from "../PortfoliosApp/PortfoliosDashboardApp";

enum Apps {
    MacroApp = 1,
    Chart = 2,
    FundamentalAnalysis = 3,
    TechnicalAnalysis = 4,
    OptionAnalysis = 5,
    ModelApp = 6,
    Portfolios = 7,
}

interface MenuItem {
    key: Apps;
    label: string;
    element?: React.ReactNode;
    disabled?: boolean;
}

let menuItems: MenuItem[] = [
    // {
    //     key: Apps.MacroApp,
    //     label: "Market Briefs",
    //     element: <MacroApp />,
    //     disabled: true,
    // },
    {
        key: Apps.Chart,
        label: "Chart",
        element: <PriceChart />,
    },
    {
        key: Apps.ModelApp,
        label: "Strategy Models",
        element: <ModelApp />,
    },
    {
        key: Apps.FundamentalAnalysis,
        label: "Fundamental Analysis",
        element: <FundamentalApp />,
    },
    {
        key: Apps.TechnicalAnalysis,
        label: "Technical Analysis",
        element: <TechnicalAnalysisApp />,
    },
    {
        key: Apps.OptionAnalysis,
        label: "Option Analysis",
        element: <div>Under Construction...</div>,
        disabled: true,
    },
    {
        key: Apps.Portfolios,
        label: "Portfolios",
        element: <PortfolioDashboardApp />,
        disabled: false,
    },
];

if (process.env.NODE_ENV === "development") {
    menuItems = menuItems.map((item) => {
        return {
            ...item,
            disabled: false,
        };
    });
}

export let items = menuItems.filter((item) => !item.disabled);
