import React, { useState } from "react";
import { Modal, Input } from "antd";
import { useNavigate } from "react-router-dom";
import SearchInput from "./SearchInput";

interface SearchModalProps {
    visible: boolean;
    onClose: () => void;
}

export const SearchModal: React.FC<SearchModalProps> = ({
    visible,
    onClose,
}) => {
    return (
        <Modal
            open={visible}
            onCancel={onClose}
            okButtonProps={{
                style: { display: "none" },
            }}
            title="Search"
        >
            <SearchInput />
        </Modal>
    );
};
