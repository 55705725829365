import React from "react";
import { StrategiesTransaction } from "../api/api";
import { formatReasonString } from "../utils";
interface TransactionInfoProps {
    transaction: StrategiesTransaction | undefined;
}

const TransactionInfo: React.FC<TransactionInfoProps> = ({ transaction }) => {
    return (
        <>
            <h3>{transaction?.action.toUpperCase()}</h3>
            <ul>{formatReasonString(transaction?.trans_factor)}</ul>
        </>
    );
};

export default TransactionInfo;
