import React from "react";
import { createBrowserRouter } from "react-router-dom";

import { authRoutes } from "../auth/routes";
import { ProtectedRoute } from "./ProtectedRoutes";

import { Main } from "../main";
import { AssetProvider } from "../hooks/AssetContext";

const routes = [
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <AssetProvider>
                    <Main />
                </AssetProvider>
            </ProtectedRoute>
        ),
    },
    {
        path: "/stocks/:assetid?",
        element: (
            <ProtectedRoute>
                <AssetProvider>
                    <Main />
                </AssetProvider>
            </ProtectedRoute>
        ),
    },
    {
        path: "/portfolio/:portfolioid?",
        element: (
            <ProtectedRoute>
                <AssetProvider>
                    <Main />
                </AssetProvider>
            </ProtectedRoute>
        ),
    },
    ...authRoutes,
];

export const Routes = createBrowserRouter(routes);
