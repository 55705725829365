import _ from "lodash";
import {
    IncomeStatementData,
    IncomeStateForPeriod,
    BalanceSheetDetails,
    BalanceSheetAsOf,
} from "../hooks/assetData";
import * as utils from "../utils";

interface IncomeStateForApp extends IncomeStatementData {
    dateString: string;
    "Gross Profit Percent": number;
    "Net Profit Percent": number;
}

interface BalanceStateForApp extends BalanceSheetDetails {
    dateString: string;
    "Total Liabilities (negative)"?: number;
}

export enum FormatType {
    INCOME = "income",
    BALANCE = "balance",
}

export const formatDateKeysToDateObject = (
    data: IncomeStateForPeriod | BalanceSheetAsOf,
    type: FormatType = FormatType.INCOME
) => {
    let _data: IncomeStateForApp[] | BalanceStateForApp[] = [];

    for (const key in data) {
        let date = new Date(parseInt(key));
        // Convert to date string: yyyy-mm-dd
        let clonedData: IncomeStateForApp | BalanceStateForApp;
        let dateString = date.toISOString().split("T")[0];
        switch (type) {
            case FormatType.INCOME:
                clonedData = _.cloneDeep(data[key]) as IncomeStateForApp;
                clonedData["Gross Profit Percent"] =
                    (clonedData["Gross Profit"] / clonedData["Total Revenue"]) *
                    100;
                clonedData["Net Profit Percent"] =
                    (clonedData["Net Income"] / clonedData["Total Revenue"]) *
                    100;
                break;
            case FormatType.BALANCE:
                clonedData = _.cloneDeep(data[key]) as BalanceStateForApp;
                clonedData["Total Liabilities (negative)"] =
                    -clonedData["Total Liabilities Net Minority Interest"];
                break;
        }
        clonedData.dateString = dateString;

        _data.push(clonedData as IncomeStateForApp & BalanceStateForApp);
    }

    return _data;
};

export const tooltipFormatter = (
    value: number | string,
    name: string,
    props: any
) => {
    const { dataKey } = props;
    // Check the dataKey to determine formatting
    if (
        typeof dataKey === "string" &&
        dataKey.includes("Percent") &&
        typeof value === "number"
    ) {
        return `${utils.roundNumberToTwoDecimalPlaces(value)}%`;
    } else if (
        typeof dataKey === "string" &&
        !dataKey.includes("Percent") &&
        typeof value === "number"
    ) {
        return `$${utils.formatNumber(value)}`; // Assuming formatBillion formats USD values
    }
};

export const formatDCFDataForChart = (data: any) => {
    let formattedData: any[] = [];
    for (const key in data.futureCashFlows) {
        formattedData.push({
            index: parseInt(key) + 1,
            futureCashFlows: data.futureCashFlows[parseInt(key)],
            presentValueFutureCashFlows:
                data.presentValueFutureCashFlows[parseInt(key)],
        });
    }
    return formattedData;
};
export * from "../utils";
