import React, { FC, useEffect, useState } from "react";
import { Table } from "antd";
import {
    Stochastics,
    StochasticsInterface,
} from "../calculators/technicalIndicators";
import { useAsset } from "../hooks/AssetContext";

const StochasticsTable: FC = () => {
    const { assetData } = useAsset();
    const [stochasticData, setStochasticData] = useState<
        StochasticsInterface[]
    >([]);
    const defaultStochasticsPeriod = [14, 20, 50, 100];
    useEffect(() => {
        if (assetData.length > 0) {
            let tmpStochastics: StochasticsInterface[] = [];
            for (const period of defaultStochasticsPeriod) {
                const stochastics = Stochastics(assetData, period);
                let lastStochastics = stochastics.slice(-1)[0];
                tmpStochastics.push({
                    rawStochastics: lastStochastics.rawStochastics,
                    key: period,
                    percentK: lastStochastics.percentK,
                    percentD: lastStochastics.percentD,
                });
            }
            setStochasticData(tmpStochastics);
        }
    }, [assetData, defaultStochasticsPeriod]);

    const columns = [
        {
            title: "Period",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "Raw Stochastics",
            dataIndex: "rawStochastics",
            key: "rawStochastics",
            render: (value: any) => parseFloat(value).toFixed(2),
        },
        {
            title: "Stochastic %K",
            dataIndex: "percentK",
            key: "percentK",
            render: (value: any) => parseFloat(value).toFixed(2),
        },
        {
            title: "Stochastic %D",
            dataIndex: "percentD",
            key: "percentD",
            render: (value: any) => parseFloat(value).toFixed(2),
        },
    ];
    return (
        <div>
            <h1>Stochastics</h1>
            <Table columns={columns} dataSource={stochasticData} />
        </div>
    );
};

export default StochasticsTable;
