import { StockData } from "../api/api";
export const SMA = (d: StockData[], window: number) => {
    if (d.length < window) {
        return [];
    }
    const smaValues: (number | undefined)[] = [];

    for (let i = 0; i < window - 1; i++) {
        smaValues.push(undefined);
    }

    for (let i = window - 1; i < d.length; i++) {
        const sum = d
            .slice(i - window + 1, i + 1)
            .reduce((acc, val) => acc + val.close, 0);
        const average = sum / window;
        smaValues.push(average);
    }

    return smaValues;
};

export interface StochasticsInterface {
    rawStochastics: number | undefined;
    key: number;
    percentK: number | undefined;
    percentD: number | undefined;
    averageTrueRange?: number;
}

export const Stochastics = (
    d: StockData[],
    window: number
): StochasticsInterface[] => {
    if (d.length < window) {
        throw new Error("Insufficient data for the specified window size");
    }
    const stochastics: StochasticsInterface[] = [];

    for (let i = 0; i < window - 1; i++) {
        stochastics.push({
            rawStochastics: undefined,
            key: i,
            percentK: undefined,
            percentD: undefined,
            averageTrueRange: undefined,
        });
    }

    for (let i = window - 1; i < d.length; i++) {
        const high = d
            .slice(i - window + 1, i + 1)
            .reduce((acc, val) => Math.max(acc, val.high), 0);
        const low = d
            .slice(i - window + 1, i + 1)
            .reduce((acc, val) => Math.min(acc, val.low), Infinity);
        const close = d[i].close;
        const current = d[i];
        const prev = d[i - 1];
        const trueRange = Math.max(
            high - low,
            Math.abs(high - current.close),
            Math.abs(low - current.close)
        );
        const rawStochastics = ((close - low) / (high - low)) * 100;
        const percentK = ((close - low) / (high - low)) * 100;
        const smoothingPeriod = 3;
        const smoothingFactor = 2 / (smoothingPeriod + 1);
        const prevPercentK = stochastics[i - 1].percentK || percentK;
        const smoothedPercentK =
            percentK * smoothingFactor + prevPercentK * (1 - smoothingFactor);
        const percentD = stochastics[i - 1].percentK;

        stochastics.push({
            rawStochastics,
            key: i,
            percentK: smoothedPercentK,
            percentD,
        });
    }

    return stochastics;
};
