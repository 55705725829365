import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    deleteAssetFromPortfolio,
    getAllAvailableStrategies,
    getPortfolio,
} from "../api/portfolios";
import { IndividualPortfolioResponse } from "../api/api";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { Button, Form, Input, Modal, Select } from "antd";
import { addAssetToPortfolio as addAssetToPortfolioAPI } from "../api/portfolios";
import { on } from "events";

const PortfolioApp: FC = () => {
    const { portfolioid } = useParams();
    const [portfolio, setPortfolio] = useState<IndividualPortfolioResponse>();
    const [addAssetForm] = Form.useForm();
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        if (portfolioid) {
            getPortfolio(portfolioid).then((portfolio) => {
                setPortfolio(portfolio);
            });
        }
    }, [portfolioid, refresh]);

    const DeleteButton = ({ onClick }: any) => {
        return (
            <Button type="primary" danger onClick={onClick}>
                Delete
            </Button>
        );
    };

    let colDef: ColDef[] = [
        { headerName: "Ticker", field: "ticker" },
        { headerName: "Quantity", field: "quantity" },
        { headerName: "Strategy", field: "strategy" },
        { headerName: "Price", field: "cost_price" },
        {
            headerName: "Delete",
            cellRenderer: DeleteButton,
            cellRendererParams: (params: any) => ({
                onClick: () => {
                    if (portfolioid === undefined) return;
                    deleteAssetFromPortfolio(
                        params.data.ticker,
                        portfolioid
                    ).then(() => {
                        setRefresh(!refresh);
                    });
                },
            }),
        },
    ];

    const addAssetToPortfolioModal = async () => {
        let strategies = await getAllAvailableStrategies();

        Modal.confirm({
            title: "Add Asset",
            content: (
                <div>
                    <Form form={addAssetForm}>
                        <Form.Item label="Ticker" name="ticker">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Quantity" name="quantity">
                            <Input type="number" />
                        </Form.Item>
                        <Form.Item label="Strategy" name="strategy">
                            <Select>
                                {strategies.map((strategy: string) => (
                                    <Select.Option
                                        key={strategy}
                                        value={strategy}
                                    >
                                        {strategy}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Cost Price" name="cost_price">
                            <Input type="number" />
                        </Form.Item>
                        <Form.Item label="Asset Type" name="asset_type">
                            <Select>
                                <Select.Option value="EQUITY">
                                    Equity
                                </Select.Option>
                                <Select.Option value="OPTIONS">
                                    Options
                                </Select.Option>
                                <Select.Option value="ETF">ETF</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
            ),
            onOk: addAssetToPortfolio,
        });
    };

    const addAssetToPortfolio = () => {
        if (portfolioid === undefined) return;
        let formValues = addAssetForm.getFieldsValue();
        formValues.cost_price = parseFloat(formValues.cost_price);
        formValues.quantity = parseInt(formValues.quantity);
        addAssetToPortfolioAPI(portfolioid, formValues).then(() => {
            setRefresh(!refresh);
        });
    };

    return (
        <div>
            <h1>{portfolio?.name}</h1>
            <p>{portfolio?.description}</p>
            <h2>Assets</h2>
            <Button type="primary" onClick={addAssetToPortfolioModal}>
                Add Asset
            </Button>
            <div className="ag-theme-quartz">
                <AgGridReact
                    columnDefs={colDef}
                    rowData={portfolio?.assets}
                    domLayout="autoHeight"
                    onGridReady={(event) => {
                        event.api.sizeColumnsToFit();
                    }}
                />
            </div>
        </div>
    );
};

export default PortfolioApp;
