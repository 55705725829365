import React from "react";
import { Menu, Button, Layout } from "antd";
import { items } from "./AppItems";

import { SearchOutlined } from "@ant-design/icons";
import plutus from "./plutus.png";
interface NavBarProps {
    selectedApp: number;
    setSelectedApp: (appNumber: number) => void;
    onSearchButtonClick: (event: any) => void;
}

const { Header } = Layout;

export const NavBar: React.FC<NavBarProps> = ({
    selectedApp,
    setSelectedApp,
    onSearchButtonClick,
}) => {
    return (
        <Header style={{ display: "flex", alignItems: "center" }}>
            <img src={plutus} style={{ height: "80%", marginRight: "8px" }} />
            <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[selectedApp.toString()]}
                items={items}
                style={{ flex: 1, minWidth: 0 }}
                onSelect={(event) => {
                    setSelectedApp(Number(event.key));
                }}
                selectedKeys={[selectedApp.toString()]}
            />
            <Button
                type="primary"
                shape="circle"
                icon={<SearchOutlined />}
                onClick={onSearchButtonClick}
            />
        </Header>
    );
};
