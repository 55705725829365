import axios, { AxiosResponse, type AxiosError } from "axios";

import { BASE_ENDPOINT } from "./urls";
import { LoginError, LoginSuccess } from "./api";

const AUTH_ENDPOINT = `${BASE_ENDPOINT}/auth`;

const LOGIN_ENDPOINT = `${AUTH_ENDPOINT}/login`;

const SIGNUP_ENDPOINT = `${AUTH_ENDPOINT}/signup`;

const ME_ENDPOINT = `${AUTH_ENDPOINT}/me`;

export const login = async (
    username: string,
    password: string,
    remember?: boolean
): Promise<LoginSuccess | LoginError> => {
    try {
        const response = await axios.post(LOGIN_ENDPOINT, {
            username,
            password,
            remember,
        });
        return response.data;
    } catch (error) {
        throw error as AxiosError<LoginError>;
    }
};

export const signup = async (
    username: string,
    email: string,
    password: string,
    confirmPassword: string
) => {
    const response = await axios.post(SIGNUP_ENDPOINT, {
        username,
        email,
        password,
        confirmPassword,
    });
    return response.data;
};

export const me = async (cookie: string) => {
    let config = {
        headers: {
            "X-Access-Token": cookie,
        },
    };
    const response = await axios.get(ME_ENDPOINT, config);
    return response.data;
};
