import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme CSS

// Define types for the performance metrics
interface PerformanceMetrics {
    Drawdown: {
        strategy: number;
        "Buy Hold": number;
    };
    Volatility: {
        strategy: number;
        "Buy Hold": number;
    };
    "Average Exposed Days Ratio": {
        strategy: number;
        "Buy Hold": number | null; // Use null for NaN values
    };
    "Net Profit Ratio": {
        strategy: number;
        "Buy Hold": number;
    };
    "Profit Factor": {
        strategy: number;
        "Buy Hold": number | null; // Use null for NaN values
    };
    "Annualized Return": {
        strategy: number;
        "Buy Hold": number | null; // Use null for NaN values
    };
    "Number of trades": {
        strategy: number;
    };
}

// Define row data structure
interface RowData {
    metric: string;
    strategy: number;
    buyhold: number | null; // Use null for missing values like NaN
}

interface PerformanceTableProps {
    performanceMetrics: PerformanceMetrics;
}

const PerformanceTable: React.FC<PerformanceTableProps> = ({
    performanceMetrics,
}) => {
    const rowData = [
        {
            metric: "Drawdown",
            strategy: performanceMetrics.Drawdown.strategy,
            buyhold: performanceMetrics.Drawdown["Buy Hold"],
        },
        {
            metric: "Volatility",
            strategy: performanceMetrics.Volatility.strategy,
            buyhold: performanceMetrics.Volatility["Buy Hold"],
        },
        {
            metric: "Average Exposed Days",
            strategy: performanceMetrics["Average Exposed Days Ratio"].strategy,
            buyhold:
                performanceMetrics["Average Exposed Days Ratio"]["Buy Hold"],
        },
        {
            metric: "Net Profit Ratio",
            strategy: performanceMetrics["Net Profit Ratio"].strategy,
            buyhold: performanceMetrics["Net Profit Ratio"]["Buy Hold"],
        },
        {
            metric: "Profit Factor",
            strategy: performanceMetrics["Profit Factor"].strategy,
            buyhold: performanceMetrics["Profit Factor"]["Buy Hold"],
        },
        {
            metric: "Annualized Return",
            strategy: performanceMetrics["Annualized Return"].strategy,
            buyhold: performanceMetrics["Annualized Return"]["Buy Hold"],
        },
        {
            metric: "Number of Trades",
            strategy: performanceMetrics["Number of trades"].strategy,
        },
    ];

    const [columnDefs] = useState([
        { headerName: "Metric", field: "metric" },
        { headerName: "Strategy", field: "strategy" },
        { headerName: "Buy Hold", field: "buyhold" },
    ] as ColDef[]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "30vh",
            }}
        >
            <div className="ag-theme-alpine" style={{ flexGrow: 1 }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onGridReady={(params) => params.api.sizeColumnsToFit()}
                />
            </div>
            <br />
        </div>
    );
};

export default PerformanceTable;
