import React, { FC, useEffect, useState } from "react";
import StrategyPerformance from "../../calculators/performance";
import { StockData, StrategiesTransaction } from "../../api/api";
import {
    CartesianGrid,
    Line,
    LineChart,
    XAxis,
    Tooltip,
    Legend,
    YAxis,
    ResponsiveContainer,
    ReferenceLine,
} from "recharts";
import dayjs from "dayjs";
import { roundNumberToTwoDecimalPlaces } from "../../utils";
import PerformanceTable from "./PerformanceTable";
import { Collapse, DatePicker } from "antd";
import axios from "axios";
import { getStrategyDataUsingCustomDataset } from "../../api/stock";
import DesktopModelTable from "../DesktopModelTable";

interface BacktestPerformanceInterface {
    assetId: string;
    assetData: StockData[];
    previousTransactions: StrategiesTransaction[];
    strategy: string;
}

const BacktestPerformanceChart: FC<BacktestPerformanceInterface> = ({
    assetId,
    assetData,
    previousTransactions,
    strategy,
}) => {
    let slicedData = assetData.slice();

    let startBalance = 1000;
    let commissions = 4;
    let taperingRatio = 1;

    const [fromDate, setFromDate] = useState(
        dayjs(slicedData.at(0)?.dateString)
    );
    const [toDate, setToDate] = useState(dayjs(slicedData.at(-1)?.dateString));
    const [data, setData] = useState<any[]>([]);
    const [performanceMetrics, setPerformanceMetrics] = useState<any>();
    const [prevTransactions, setPrevTransactions] =
        useState(previousTransactions);
    useEffect(() => {
        const fromDateString = fromDate.format("YYYY-MM-DD");
        const toDateString = toDate.format("YYYY-MM-DD");

        // Filter assetData between fromDate and toDate
        let filteredData = slicedData;
        try {
            filteredData = slicedData.filter(
                (data: any) =>
                    data.dateString >= fromDateString &&
                    data.dateString <= toDateString
            );
        } catch {}
        if (filteredData.length <= 0) {
            filteredData = slicedData;
        }
        let prevTrans = previousTransactions;
        if (assetData[0].dateString !== fromDateString) {
            getStrategyDataUsingCustomDataset(
                filteredData,
                strategy,
                assetId
            ).then((res) => {
                prevTrans = res;
                setPrevTransactions(JSON.parse(JSON.stringify(prevTrans)));
            });
        }

        let calculator = new StrategyPerformance({
            previousTransactions: prevTrans,
            assetData: filteredData,
        });
        let data = calculator.getMarketValueOfInvestment(
            startBalance,
            commissions,
            taperingRatio
        );
        setData(data);
        let strategyMktValueArray = data.map((d: any) => d.mktValue);
        let buyholdMktValueArray = data.map((d: any) => d.buyHoldMktValue);
        let performanceMetrics = {
            Drawdown: {
                strategy: calculator.getMaxDrawDown(strategyMktValueArray),
                "Buy Hold": calculator.getMaxDrawDown(buyholdMktValueArray),
            },
            Volatility: {
                strategy: (() => {
                    try {
                        return calculator.getVolatilityOfMktValue(
                            strategyMktValueArray
                        );
                    } catch (error) {
                        return NaN; // Return NaN if an error occurs
                    }
                })(),
                "Buy Hold": (() => {
                    try {
                        return calculator.getVolatilityOfMktValue(
                            buyholdMktValueArray
                        );
                    } catch (error) {
                        return NaN; // Return NaN if an error occurs
                    }
                })(),
            },
            "Average Exposed Days Ratio": {
                strategy: calculator.getExposedToTotalDays(),
                "Buy Hold": 1,
            },
            "Net Profit Ratio": {
                strategy:
                    calculator.getNetProfit(
                        strategyMktValueArray,
                        startBalance
                    ) / startBalance,
                "Buy Hold":
                    calculator.getNetProfit(
                        buyholdMktValueArray,
                        startBalance
                    ) / startBalance,
            },
            "Profit Factor": {
                strategy: calculator.calculateProfitFactor(),
                "Buy Hold": NaN,
            },
            "Annualized Return": {
                strategy: calculator.getAnnualizedReturn(
                    startBalance,
                    strategyMktValueArray
                ),
                "Buy Hold": calculator.getAnnualizedReturn(
                    startBalance,
                    buyholdMktValueArray
                ),
            },
            "Number of trades": {
                strategy: Math.floor(prevTrans.length / 2),
            },
        };
        setPerformanceMetrics(performanceMetrics);
    }, [fromDate, toDate, assetData]);

    return (
        <div>
            {performanceMetrics && (
                <PerformanceTable performanceMetrics={performanceMetrics} />
            )}
            <br />
            <div>
                <h3>Backtest Period</h3>
                <label>
                    From:{" "}
                    <DatePicker
                        value={fromDate}
                        onChange={(date) => setFromDate(date)}
                        allowClear={false}
                        // maxDate={dayjs(assetData.at(-50)?.dateString)}
                    />
                </label>
                <label>
                    To:{" "}
                    <DatePicker
                        value={toDate}
                        onChange={(date) => setToDate(date)}
                        allowClear={false}
                        // minDate={dayjs(fromDate)}
                        // maxDate={dayjs(assetData.at(-1)?.dateString)}
                    />
                </label>
            </div>
            <ResponsiveContainer width={"100%"} height={400}>
                <LineChart data={data}>
                    <XAxis
                        dataKey={"date"}
                        scale="time"
                        tickFormatter={(unixTime) =>
                            dayjs(unixTime).format("YYYY-MM")
                        }
                        allowDataOverflow
                    />
                    <YAxis orientation="right" />
                    <Legend />
                    <Line
                        type="natural"
                        dataKey={"buyHoldMktValue"}
                        stroke="#000000"
                        dot={false}
                    />
                    <ReferenceLine
                        y={startBalance}
                        stroke="green"
                        strokeDasharray="3 3"
                    />
                    <Line type="natural" dataKey={"mktValue"} dot={false} />
                    <CartesianGrid />
                </LineChart>
            </ResponsiveContainer>
            <Collapse
                items={[
                    {
                        key: 1,
                        label: "Trades",
                        children: <DesktopModelTable data={prevTransactions} />,
                    },
                ]}
            />
        </div>
    );
};

export default BacktestPerformanceChart;
