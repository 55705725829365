import React, { useEffect, useRef, useState } from "react";
import {
    HoverTooltip,
    Annotate,
    LabelAnnotation,
    withSize,
    withDeviceRatio,
} from "react-financial-charts";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    BarSeries,
    CandlestickSeries,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    LineSeries,
    CurrentCoordinate,
    TrendLine,
    OHLCTooltip,
    MovingAverageTooltip,
} from "react-financial-charts";
import _ from "lodash";

import { TrendLineInterface, FinancialChartProps } from "./chart";
import { StockData } from "../api/api";
import { useAsset } from "../hooks/AssetContext";
import Loader from "../common/Loader";
import {
    coordinateStyles,
    axisStyles,
    zoomButtonStyles,
    crossHairStyles,
} from "./chartstyles";

import { yExtentsCalculator, openCloseColor } from "./utils";
import StrategyInUse from "./StrategyInUse";
import { Modal } from "antd";
import { convertDateToStringFormat, formatReasonString } from "../utils";
import TransactionInfo from "./TransactionInfo";

const halfWidth = 10;
const bottomWidth = 3;
const height = 20;

export function buyPath({ x, y }: any) {
    return (
        `M${x} ${y} ` +
        `L${x + halfWidth} ${y + halfWidth} ` +
        `L${x + bottomWidth} ${y + halfWidth} ` +
        `L${x + bottomWidth} ${y + height} ` +
        `L${x - bottomWidth} ${y + height} ` +
        `L${x - bottomWidth} ${y + halfWidth} ` +
        `L${x - halfWidth} ${y + halfWidth} ` +
        "Z"
    );
}

const FinancialChart: React.FC<FinancialChartProps> = ({
    dateTimeFormat = "%d %b '%y",
    margin = { left: 0, right: 48, top: 0, bottom: 24 },
    priceDisplayFormat = format(".2f"),
    height,
    ratio,
    width,
}) => {
    const [resetCount, setResetCount] = useState(0);
    const {
        asset,
        loaded,
        assetData,
        error,
        pending,
        technicalData,
        strategyData,
    } = useAsset();
    const [initialData, setInitialData] = useState<StockData[]>([]);
    const [trends, setTrends] = useState<TrendLineInterface[]>([]);
    // const [technicals, setSmas] = useState<AssetTechnicalData | undefined>(undefined);
    useEffect(() => {
        if (asset && assetData) {
            console.log(assetData);
            setInitialData(assetData);
        }
    }, [asset, assetData]);

    const [enableTrendline, setEnableTrendline] = useState(false);
    const [selectStrategy, setSelectStrategy] = useState("");

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768); // Adjust width threshold as needed
        };

        window.addEventListener("resize", handleResize);

        handleResize(); // Call on initial render

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (strategyData && Object.keys(strategyData).length > 0) {
            let strategy = Object.keys(strategyData)[0];
            setSelectStrategy(strategy);
        }
    }, [strategyData]);

    const deleteSelectedTrendline = (trends: TrendLineInterface[]) => {
        let filteredTrends = trends.filter((trend) => !trend.selected);
        setTrends(filteredTrends);
    };
    const defaultAnnotationProps = {
        fontFamily: "Glyphicons Halflings",
        fontSize: 14,
        opacity: 0.8,
        onClick: (e: React.MouseEvent, { datum }: any) => {
            Modal.info({
                title: `Transaction Reason on ${convertDateToStringFormat(
                    datum.idx.date
                )}`,
                content: (
                    <TransactionInfo
                        transaction={strategyData[
                            selectStrategy
                        ].previous_transactions.find(
                            (trans) => trans.index === datum.idx.index
                        )}
                    />
                ),
            });
        },
    };
    const onKeyboardEvent = (event: KeyboardEvent) => {
        let trendsClone = _.cloneDeep(trends);
        switch (event.key) {
            case "t":
                setEnableTrendline(true);
                break;
            case "Escape":
                setEnableTrendline(false);
                break;
            case "Delete":
            case "Backspace":
                deleteSelectedTrendline(trendsClone);
                break;
            default:
                break;
        }
    };
    const longAnnotationProps = {
        ...defaultAnnotationProps,
        fill: "#006517",
        text: "⬆️",
        y: ({ yScale, datum }: any) => yScale(datum.low) + 20,
    };

    const exitAnnotationProps = {
        ...defaultAnnotationProps,
        fill: "#FF0000",
        text: (datum: any) => {
            let trans = strategyData[selectStrategy].previous_transactions.find(
                (trans) => trans.index === datum.idx.index
            );
            if (trans?.trans_factor === "stoploss") return "💣";
            return "⬇️";
        },
        y: ({ yScale, datum }: any) => yScale(datum.high) - 20,
    };
    useEffect(() => {
        document.addEventListener("keydown", onKeyboardEvent);

        return () => {
            document.removeEventListener("keydown", onKeyboardEvent);
        };
    }, [JSON.stringify(trends)]);

    const xScaleProvider =
        discontinuousTimeScaleProviderBuilder().inputDateAccessor(
            (d) => d.date
        );
    const { data, xScale, xAccessor, displayXAccessor } =
        xScaleProvider(initialData);
    const min = xAccessor(data[Math.max(0, data.length - 220)]);
    const max = xAccessor(data[data.length - 1]);
    const [xExtents, setXExtents] = useState<[number, number]>(() => {
        return [min, max + 20];
    });
    useEffect(() => {
        setXExtents([min, max + 20]);
    }, [min, max]);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const startXRef = useRef<number>(0);
    const isPanningRef = useRef<boolean>(false);

    useEffect(() => {
        const handleTouchStart = (event: TouchEvent) => {
            if (event.touches.length === 1) {
                const touch = event.touches[0];
                startXRef.current = touch.clientX;
                isPanningRef.current = true;
                event.preventDefault();
            }
        };

        const handleTouchMove = (event: TouchEvent) => {
            event.stopPropagation();

            if (!isPanningRef.current) return;
            const touch = event.touches[0];
            let dx = touch.clientX - startXRef.current;
            dx = Math.round(dx / 10);
            setXExtents((prev) => {
                if (prev[0] - dx >= data.length - 1) {
                    return prev;
                } else {
                    let difference = prev[0] - dx;
                    let maxX = prev[1] - dx;
                    if (difference < 0) {
                        difference = 0;
                        maxX = 120;
                    }
                    return [difference, maxX];
                }
            });
        };

        const handleTouchEnd = (event: TouchEvent) => {
            event.stopPropagation();
            isPanningRef.current = false;
        };

        const container = containerRef.current;

        if (isMobile && container) {
            container.addEventListener("touchstart", handleTouchStart);
            container.addEventListener("touchmove", handleTouchMove);
            container.addEventListener("touchend", handleTouchEnd);
        }

        return () => {
            if (container) {
                container.removeEventListener("touchstart", handleTouchStart);
                container.removeEventListener("touchmove", handleTouchMove);
                container.removeEventListener("touchend", handleTouchEnd);
            }
        };
    }, [xAccessor, xExtents, data, displayXAccessor, isMobile]);

    const gridHeight = height ? height - margin.top - margin.bottom : 0;
    const volumeChartHeight = gridHeight / 5;
    const volumeChartOrigin = (_: number, h: number) => [
        0,
        h - volumeChartHeight - 20,
    ];

    const lrChartOrigin = (_: number, h: number) => [
        0,
        h - volumeChartHeight + 30,
    ];

    const onTrendlineComplete = (
        _e: React.MouseEvent,
        newTrends: TrendLineInterface[],
        _moreProps: any
    ) => {
        setTrends([...newTrends]);
        setEnableTrendline(false);
    };

    const generateTooltipContent = (data: any) => {
        if (data.currentItem) {
            let date = data.currentItem?.idx.date;
            let formattedDate = convertDateToStringFormat(
                new Date("2021-01-01")
            );
            if (date) {
                formattedDate = convertDateToStringFormat(new Date(date));
            }
            const spread = data.currentItem.spread?.toFixed(2);
            return {
                x: formattedDate,
                y: [
                    {
                        label: "50 Days Envelope Spread",
                        value: spread,
                        stroke: "blue",
                    },
                ],
            };
        } else {
            return {
                x: "Loading...",
                y: [],
            };
        }
    };

    if (error) return <div>{error}</div>;
    if (pending) return <Loader spinning={pending} />;
    if (!loaded || !height || !ratio || !width || !xExtents) return null;
    return (
        <div style={{ height }}>
            <StrategyInUse
                name={selectStrategy}
                setStrategy={setSelectStrategy}
                strategies={Object.keys(strategyData)}
            />
            <div ref={containerRef}>
                <ChartCanvas
                    height={gridHeight}
                    ratio={ratio}
                    width={window.innerWidth - 41}
                    margin={margin}
                    seriesName={`Chart ${resetCount}`}
                    data={data}
                    xScale={xScale}
                    xAccessor={xAccessor}
                    displayXAccessor={displayXAccessor}
                    xExtents={xExtents}
                    zoomAnchor={lastVisibleItemBasedZoomAnchor}
                    disablePan={isMobile}
                    disableZoom={isMobile}
                >
                    <Chart
                        id={1}
                        yExtentsCalculator={
                            isMobile ? undefined : yExtentsCalculator
                        }
                        yExtents={
                            isMobile
                                ? (d: any) => [d.high * 1.4, d.low * 0.6]
                                : undefined
                        }
                        height={gridHeight - margin.top - margin.bottom - 20}
                    >
                        <XAxis {...axisStyles} showGridLines />
                        <YAxis
                            {...axisStyles}
                            showGridLines
                            zoomEnabled={false}
                        />
                        <MouseCoordinateY
                            rectWidth={margin.right}
                            displayFormat={priceDisplayFormat}
                            {...coordinateStyles}
                        />

                        {/* YAxis close price highlight */}
                        <EdgeIndicator
                            itemType="last"
                            rectWidth={margin.right}
                            fill={openCloseColor}
                            lineStroke={openCloseColor}
                            displayFormat={priceDisplayFormat}
                            yAccessor={(d) => d.close}
                        />

                        <CandlestickSeries />
                        {/* {technicalData &&
                            Object.keys(technicalData).map((window) => {
                                let types = technicalData[window];
                                let values = types.sma;
                                let color = types.color;
                                return (
                                    <div key={window}>
                                        <LineSeries
                                            yAccessor={(d: any) => {
                                                let idx = d.idx.index;
                                                return values[idx];
                                            }}
                                            strokeStyle={color}
                                        />
                                    </div>
                                );
                            })} */}

                        {strategyData &&
                            strategyData?.[
                                selectStrategy
                            ]?.display_indicators.map((strategyIndicator) => {
                                const { indicator_name, stroke_style } =
                                    strategyIndicator;
                                return (
                                    <LineSeries
                                        yAccessor={(d) => d?.[indicator_name]}
                                        strokeStyle={stroke_style}
                                    />
                                );
                            })}
                        <OHLCTooltip origin={[8, 16]} />
                        <TrendLine
                            enabled={enableTrendline} // Use a Event/Button to trigger this.
                            type="RAY"
                            snap={true}
                            onComplete={onTrendlineComplete}
                            trends={trends}
                        />
                        <ZoomButtons
                            onReset={() => setResetCount(resetCount + 1)}
                            {...zoomButtonStyles}
                        />
                        <Annotate
                            with={LabelAnnotation}
                            when={(d) => {
                                let idx = d.idx.index;
                                // Ensure that selectStrategy is in strategyData
                                if (!strategyData[selectStrategy]) return false;
                                let transaction = strategyData[
                                    selectStrategy
                                ].previous_transactions.find(
                                    (data) => data.index === idx
                                );
                                return transaction?.action === "Buy";
                            }}
                            usingProps={longAnnotationProps}
                        />
                        <Annotate
                            with={LabelAnnotation}
                            when={(d) => {
                                let idx = d.idx.index;
                                if (!strategyData[selectStrategy]) return false;
                                let transaction = strategyData[
                                    selectStrategy
                                ].previous_transactions.find(
                                    (data) =>
                                        data.index === idx &&
                                        data.action === "Sell"
                                );
                                if (transaction) return true;
                                else return false;
                                // return transaction?.action === "Sell";
                            }}
                            usingProps={exitAnnotationProps}
                        />
                        <MovingAverageTooltip
                            origin={[8, 24]}
                            options={[
                                {
                                    yAccessor: (d: any) => {
                                        let idx = d.idx.index;
                                        return (
                                            technicalData["50"].sma[idx] ?? 0
                                        );
                                    },
                                    type: "SMA",
                                    stroke: "red",
                                    windowSize: 50,
                                },
                                {
                                    yAccessor: (d: any) => {
                                        let idx = d.idx.index;
                                        return (
                                            technicalData["100"].sma[idx] ?? 0
                                        );
                                    },
                                    type: "SMA",
                                    stroke: "orange",
                                    windowSize: 100,
                                },
                                {
                                    yAccessor: (d: any) => {
                                        let idx = d.idx.index;
                                        return (
                                            technicalData["200"].sma[idx] ?? 0
                                        );
                                    },
                                    type: "SMA",
                                    stroke: "yellow",
                                    windowSize: 200,
                                },
                            ]}
                        />
                        <HoverTooltip
                            yAccessor={(d) => d.lr_slope_20}
                            tooltip={{
                                content: generateTooltipContent,
                            }}
                        />
                    </Chart>

                    <Chart
                        id={2}
                        height={volumeChartHeight}
                        origin={volumeChartOrigin}
                        yExtents={(d) => d.volume}
                    >
                        <BarSeries
                            fillStyle={(d) =>
                                d.close > d.open
                                    ? "rgba(38, 166, 154, 0.3)"
                                    : "rgba(239, 83, 80, 0.3)"
                            }
                            yAccessor={(d) => d.volume}
                        />
                    </Chart>
                    <CrossHairCursor {...crossHairStyles} />
                </ChartCanvas>
            </div>
        </div>
    );
};

// export const PriceChart = withSize({ style: { minHeight: 600 } })(
//     withDeviceRatio()(FinancialChart as any) as any
// );

export const PriceChart = withSize({
    style: { minHeight: Math.min(520, window.innerHeight * 1.8) - 20 },
})(withDeviceRatio()(FinancialChart as any) as any);
