import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";

const SearchInput = () => {
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();

    const onSearch = () => {
        navigate(`/stocks/${searchValue}`);
    };

    const onUpdateSearchValue = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchValue(event.target.value.toUpperCase());
    };
    return (
        <Input
            placeholder="Enter a symbol"
            onChange={onUpdateSearchValue}
            onPressEnter={onSearch}
        />
    );
};

export default SearchInput;
