import React, { useEffect, useState } from "react";
import { theme } from "antd";

import { AssetProvider, useAsset } from "./hooks/AssetContext";
import { items } from "./common/AppItems";

import { NavBar } from "./common/Header";
import { ContentContainer } from "./common/ContentContainer";
import { useParams } from "react-router-dom";

export const Main: React.FC = () => {
    const {
        token: { borderRadiusLG },
    } = theme.useToken();

    const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
    const [selectedApp, setSelectedApp] = useState(items[0].key);
    const { assetid, portfolioid } = useParams();
    const { updateAsset } = useAsset();

    const onSearchButtonClick = () => {
        setIsSearchModalVisible(!isSearchModalVisible);
    };

    useEffect(() => {
        if (assetid) {
            if (selectedApp === 1 || selectedApp === 7) {
                setSelectedApp(2);
            }
            updateAsset(assetid);
        }
    }, [assetid]);

    useEffect(() => {
        if (portfolioid || window.location.pathname.includes("/portfolio")) {
            setSelectedApp(7);
        }
    }, [portfolioid, window.location.pathname]);

    return (
        <div style={{ minHeight: "100vh" }}>
            <NavBar
                selectedApp={selectedApp}
                setSelectedApp={setSelectedApp}
                onSearchButtonClick={onSearchButtonClick}
            />

            <ContentContainer
                borderRadiusLG={borderRadiusLG}
                isSearchModalVisible={isSearchModalVisible}
                onSearchButtonClick={onSearchButtonClick}
                selectedApp={selectedApp}
            />
        </div>
    );
};
