import React from "react";
import { StrategiesTransaction } from "../api/api";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { formatReasonString } from "../utils";

interface ModelTableProps {
    data: StrategiesTransaction[];
}

const ModelTable: React.FC<ModelTableProps> = ({ data }) => {
    const colDefs: ColDef[] = [
        { headerName: "Date", field: "date" },
        { headerName: "Action", field: "action" },
        {
            headerName: "Reason",
            field: "trans_factor",
            cellRenderer: (params: any) => {
                let reason = params.value;
                // Change it to a un-ordered list of reason
                if (reason) {
                    return formatReasonString(reason);
                }
                return <div>{params.value}</div>;
            },
        },
        {
            headerName: "Transacted Price",
            field: "price",
            valueFormatter: (params) => {
                return params.value ? params.value.toFixed(2) : params.value;
            },
            cellClass: "align-right",
        },

        {
            headerName: "Profit",
            valueGetter: (params) => {
                if (params.data.action === "Sell") {
                    const currentRowIndex = params.node?.rowIndex; // Add null check for params.node
                    const previousBuy = currentRowIndex
                        ? params.api.getDisplayedRowAtIndex(currentRowIndex - 1)
                        : null; // Add null check for currentRowIndex
                    if (previousBuy && previousBuy.data.action === "Buy") {
                        const profit =
                            params.data.price - previousBuy.data.price;
                        return profit.toFixed(2);
                    }
                }
                return "";
            },
            cellClass: "align-right",
        },
        {
            headerName: "Holding Period",
            valueGetter: (params) => {
                if (params.data.action === "Sell") {
                    const currentRowIndex = params.node?.rowIndex; // Add null check for params.node
                    const previousBuy = currentRowIndex
                        ? params.api.getDisplayedRowAtIndex(currentRowIndex - 1)
                        : null; // Add null check for currentRowIndex
                    if (previousBuy && previousBuy.data.action === "Buy") {
                        const days = params.data.index - previousBuy.data.index;
                        return days.toFixed(1);
                    }
                }
            },
            cellClass: "align-right",
        },
    ];
    const getRowHeight = (params: any) => {
        if (params.data.trans_factor) {
            const reasons = params.data.trans_factor.split("+");
            return reasons.length * 30 + 20; // Adjust based on your needs
        }
        return 30; // Default row height
    };
    return (
        <div className="ag-theme-quartz">
            <AgGridReact
                rowData={data}
                columnDefs={colDefs}
                domLayout="autoHeight"
                getRowHeight={getRowHeight}
                onGridReady={(event) => {
                    event.api.sizeColumnsToFit();
                }}
            />
        </div>
    );
};

const DesktopModelTable = React.memo(ModelTable);
export default DesktopModelTable;
