import React from "react";
import { Collapse, Descriptions, type DescriptionsProps, Flex } from "antd";
import WatchlistButton from "./WatchlistButton";
import { useAsset } from "../hooks/AssetContext";
import {
    roundNumberToTwoDecimalPlaces,
    commaSeparatedNumber,
    formatNumber,
    calculateVolatility,
} from "../utils";
import "./AssetInfo.css";

export const AssetInfo = () => {
    const { asset, assetData, assetInfo } = useAsset();

    let descriptionItem: DescriptionsProps["items"] = [
        {
            key: "1",
            label: "Last Price",
            children: `$${roundNumberToTwoDecimalPlaces(
                assetInfo?.lastPrice || 0
            )}`,
        },
        {
            key: "2",
            label: "Price to Earnings Ratio",
            children: `${roundNumberToTwoDecimalPlaces(
                assetInfo?.trailingPE || 0
            )}`,
        },
        {
            key: "3",
            label: "Price to Book Ratio",
            children: `${roundNumberToTwoDecimalPlaces(
                assetInfo?.priceToBook || 0
            )}`,
        },
        {
            key: "4",
            label: "Market Capitalization",
            children: `$${formatNumber(assetInfo?.marketCap || 0)}`,
        },
        {
            key: "5",
            label: "30d Volatilitiy",
            children: `${roundNumberToTwoDecimalPlaces(
                calculateVolatility(assetData, 30)
            )}%`,
        },
        {
            key: "6",
            label: "Sector",
            children: assetInfo?.sector || "N/A",
        },
    ];

    let items = [
        {
            key: "1",
            label: `Ticker: ${asset}`,
            children: <Descriptions items={descriptionItem} />,
        },
    ];

    return (
        <Flex className="asset-id-container">
            <h3>{asset}</h3>
        </Flex>
    );
};
