import React, { useMemo } from "react";
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from "recharts";

import { IncomeStateForPeriod } from "../hooks/assetData";
import {
    formatDateKeysToDateObject,
    formatNumber,
    tooltipFormatter,
} from "./utils";

interface IncomeStatementAppProps {
    data: IncomeStateForPeriod;
}

const IncomeStatementApp: React.FC<IncomeStatementAppProps> = ({ data }) => {
    const formattedData = useMemo(() => {
        return formatDateKeysToDateObject(data);
    }, [data]);

    return (
        <div>
            <ComposedChart
                width={500}
                height={400}
                data={formattedData}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
                stackOffset="sign"
            >
                <XAxis dataKey="dateString" />
                <YAxis
                    type="number"
                    tickFormatter={(value) => `${formatNumber(value)}`}
                />
                <YAxis
                    type="number"
                    tickFormatter={(value) => `${value}%`}
                    axisLine={false}
                    tickLine={false}
                    orientation="right"
                    yAxisId={"percent"}
                />
                <Tooltip formatter={tooltipFormatter} />
                <Legend />
                <Bar
                    dataKey="Net Income"
                    barSize={20}
                    fill="#green"
                    stackId={"IncomeStatement"}
                />
                <Bar
                    dataKey="Total Expenses"
                    barSize={20}
                    fill="#413ea0"
                    stackId={"IncomeStatement"}
                />
                <Line
                    type="monotone"
                    dataKey="Gross Profit Percent"
                    stroke="red"
                    yAxisId={"percent"}
                />
                <Line
                    type="monotone"
                    dataKey="Net Profit Percent"
                    stroke="green"
                    yAxisId={"percent"}
                />
            </ComposedChart>
        </div>
    );
};

export default IncomeStatementApp;
