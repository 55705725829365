import React, { useEffect, useState } from "react";
import { useAsset } from "../hooks/AssetContext";
import Description from "../common/Description";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import "./modeltable.css";

import { convertToNormalString, formatReasonString } from "../utils";
import DesktopModelTable from "./DesktopModelTable";
import MobileModelTable from "./MobileModelTable";
import BacktestPerformanceChart from "./backtest/BacktestPerformanceChart";
import { Collapse } from "antd";

const ModelApp: React.FC = () => {
    const { assetData, strategyData, asset } = useAsset();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768); // Adjust width threshold as needed
        };

        window.addEventListener("resize", handleResize);

        handleResize(); // Call on initial render

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <div>
            <h4>
                Have a strategy? Click{" "}
                <a href="mailto:siyang@wongpro.com">here</a> to submit your
                strategy.
            </h4>
            {Object.keys(strategyData).map((strategy) => (
                <div key={strategy}>
                    <h2>{convertToNormalString(strategy)}</h2>
                    <Description
                        description={strategyData[strategy].description}
                    />
                    <BacktestPerformanceChart
                        assetId={asset}
                        assetData={assetData}
                        previousTransactions={
                            strategyData[strategy].previous_transactions
                        }
                        strategy={strategy}
                    />

                    <br />
                </div>
            ))}
        </div>
    );
};

export default ModelApp;
