import "./App.css";
import { Routes } from "./routes/route";
import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./hooks/AuthContext";

function App(): JSX.Element {
    return (
        <div className="App">
            <AuthProvider>
                <RouterProvider router={Routes} />
            </AuthProvider>
        </div>
    );
}

export default App;
