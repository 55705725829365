import React, { useMemo } from "react";
import { Col, Row, Typography } from "antd";

import { useAsset } from "../hooks/AssetContext";
import DCFCalculatorApp from "./DCFCalculatorApp";
import { IncomeStateForPeriod } from "../hooks/assetData";
import { getLargestStringNumber } from "../utils";
import IncomeStatementApp from "./IncomeStatementApp";
import BalanceSheetApp from "./BalanceSheetApp";

interface FundamentalProps {}
const FundamentalApp: React.FC<FundamentalProps> = () => {
    const { assetInfo, fundamentalData } = useAsset();

    const incomeStatements: IncomeStateForPeriod = useMemo(() => {
        return fundamentalData?.earnings;
    }, [fundamentalData]);

    const balancesheets = useMemo(() => {
        return fundamentalData?.balancesheet;
    }, [fundamentalData]);

    const latestFreeCashFlow = assetInfo!.freeCashflow / assetInfo!.shares;

    let fundamentalAppItems = [
        {
            element: <IncomeStatementApp data={incomeStatements} />,
            key: "income-statement-app",
            title: "Income Statement",
        },
        {
            element: <BalanceSheetApp data={balancesheets} />,
            key: "balance-sheet-app",
            title: "Balance Sheet",
        },
    ];
    return (
        <>
            <Row gutter={16}>
                {fundamentalAppItems.map((appItem, idx) => (
                    <Col key={`fundamental-app-${appItem.key}`} xs={24} md={12}>
                        <Typography.Title style={{ textAlign: "center" }}>
                            {appItem.title}
                        </Typography.Title>
                        {appItem.element}
                    </Col>
                ))}
            </Row>
            <Typography.Title style={{ textAlign: "center" }}>
                Instrinsic Value Calculator
            </Typography.Title>
            <Row gutter={[16, 16]}>
                <DCFCalculatorApp
                    freeCashFlow={latestFreeCashFlow}
                    price={assetInfo.previousClose}
                />
            </Row>
        </>
    );
};

export default FundamentalApp;
