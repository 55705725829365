import { StockData } from "../api/api";

export const convertDateStringtoDateObject = (date: string, purpose = "") => {
    return new Date(date);
};

// Default green/red colors for candlesticks
export const openCloseColor = (d: any) =>
    d.close > d.open ? "#26a69a" : "#ef5350";

// yExtentsCalculator: used from updating price series
// https://github.com/react-financial/react-financial-charts/blob/main/packages/stories/src/features/updating/BasicLineSeries.tsx#L55
export const yExtentsCalculator = ({ plotData }: { plotData: any }) => {
    let min;
    let max;
    for (const { low, high } of plotData) {
        if (min === undefined) min = low;
        if (max === undefined) max = high;
        if (low !== undefined && min > low) min = low;
        if (high !== undefined && max < high) max = high;
    }
    if (min === undefined) min = 0;
    if (max === undefined) max = 0;

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding * 2];
};
