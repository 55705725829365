import React, { useState } from "react";
import { Form, Button, Checkbox, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { login } from "../api/auth";
import { useAuth } from "../hooks/AuthContext";
import { UserInterface } from "./UserInterface";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Login = (props: any) => {
    const [form] = Form.useForm();
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { login: loginContext } = useAuth();
    const handleSubmit = (event: React.FormEvent) => {
        setError("");
        form.validateFields()
            .then((values: UserInterface) => {
                let { username, password, remember } = values;
                login(username, password, remember)
                    .then((response) => {
                        if ("access_token" in response) {
                            loginContext(response.access_token);
                            const path = searchParams.get("path");
                            navigate(`${path ? path : "/"}`);
                        }
                    })
                    .catch((error): void => {
                        setError(error.response.data.error);
                    });
            })
            .catch((error): void => {
                console.error(error);
            });
    };

    return (
        <div className="glass-container">
            <h1>Login</h1>
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: "Please input your Username!",
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Username"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Password Required" }]}
                >
                    <Input
                        prefix={
                            <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <a className="login-form-forgot" href="">
                        Forgot password
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        Log in
                    </Button>
                </Form.Item>
            </Form>
            <p>Want an account? Register for the waitlist! </p>
            <a href="/signup">Register now!</a>
        </div>
    );
};
