import React, { FC } from "react";
import { Form, Modal, type ModalProps, Input } from "antd";
import { createPortfolio } from "../api/portfolios";
import { useNavigate } from "react-router-dom";

interface CreatePortfolioModalProps extends ModalProps {
    toggleCreatePortfolioModal: any;
}

const CreatePorfolioModal: FC<CreatePortfolioModalProps> = ({
    toggleCreatePortfolioModal,
    open,
}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const onFinish = (event: any) => {
        // Get the form values from from.getFieldsValue()
        // and pass them to the API
        let payload = form.getFieldsValue();
        createPortfolio(payload).then((response) => {
            Modal.info({
                title: "Portfolio Created",
                content: (
                    <div>
                        <p>Portfolio created successfully</p>
                    </div>
                ),
                onOk() {
                    navigate("/portfolio/" + response.id);
                    toggleCreatePortfolioModal();
                },
            });
        });
    };

    return (
        <Modal
            open={open}
            onCancel={toggleCreatePortfolioModal}
            title="Create Portfolio"
            onOk={onFinish}
        >
            <Form form={form}>
                <Form.Item label="Name" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreatePorfolioModal;
