import { Select } from "antd";
import React, { useEffect } from "react";
import { convertToNormalString } from "../utils";

interface StrategyInUseProps {
    name: string;
    setStrategy: (name: string) => void;
    strategies: string[];
}

const StrategyInUse: React.FC<StrategyInUseProps> = ({
    name,
    setStrategy,
    strategies,
}) => {
    return (
        <>
            <label htmlFor="strategy-select" style={{ marginRight: "8px" }}>
                Strategy
            </label>
            <Select
                value={name}
                onChange={setStrategy}
                id="strategy-select"
                style={{ width: "300px" }}
            >
                {strategies.map((strategy) => (
                    <Select.Option key={strategy} value={strategy}>
                        {convertToNormalString(strategy)}
                    </Select.Option>
                ))}
                <Select.Option key="none" value="none">
                    None
                </Select.Option>
            </Select>
        </>
    );
};

export default StrategyInUse;
