import React, { ReactElement, useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/AuthContext";
import { me } from "../api/auth";

interface ProtectedRouteProps {
    children: ReactElement;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
    const { authenticated, login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState(
        location.pathname + location.search
    );

    useEffect(() => {
        let cookie = sessionStorage.getItem("token") || "";
        me(cookie)
            .then((data: string) => {
                if (data) {
                    login(data);
                    // Navigate to current url with params
                    if (currentUrl) {
                        navigate(currentUrl);
                    } else {
                        navigate("/");
                    }
                }
            })
            .catch(() => {});
    }, []);

    return authenticated ? (
        <>{props.children}</>
    ) : (
        <Navigate to={`/login?path=${location.pathname}`} />
    );
};
