import { Login } from "./Login";
import { Signup } from "./Signup";
import { RouteObject } from "react-router-dom";

import "./auth.css";

export const authRoutes: RouteObject[] = [
    {
        path: "/login",
        element: (
            <div className="auth-body">
                <Login />
            </div>
        ),
    },
    {
        path: "/signup",
        element: (
            <div className="auth-body">
                <Signup />
            </div>
        ),
    },
];
