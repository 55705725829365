import React from "react";

import { Spin, SpinProps } from "antd";

const Loader: React.FC<SpinProps> = ({ spinning }) => {
    return (
        <>
            <Spin spinning={spinning} fullscreen />
        </>
    );
};

export default Loader;
