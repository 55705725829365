import React, { useEffect, useState, memo } from "react";
import { deletePortfolio, getUserPortfolios } from "../api/portfolios";
import { UserPortfolios } from "../api/api";
import { Row, Col, Card, Modal } from "antd";
import { Meta } from "antd/es/list/Item";

import "./PortfoliosApp.css";
import { useNavigate, useParams } from "react-router-dom";
import CreatePorfolioModal from "./CreatePortfolioModal";
import { DeleteOutlined } from "@ant-design/icons";

const PortfolioDashboardApp: React.FC = () => {
    const [portfolios, setPortfolios] = useState<UserPortfolios[]>([]);
    const [showCreatePortfolioModal, setShowCreatePortfolioModal] =
        useState(false);
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const { portfolioid } = useParams();
    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const response = await getUserPortfolios();
                // Sort portfolios by created_date
                response.portfolios.sort(
                    (a, b) =>
                        new Date(b.created_at).getTime() -
                        new Date(a.created_at).getTime()
                );
                setPortfolios(response.portfolios);
            } catch (error) {
                console.error("Error fetching portfolios:", error);
            }
        };

        fetchPortfolios();
    }, [portfolioid, refresh]);

    const toggleCreatePortfolioModal = () => {
        setShowCreatePortfolioModal(!showCreatePortfolioModal);
    };

    const handleDeletePortfolio = (portfolioId: string) => {
        Modal.confirm({
            title: "Delete Portfolio",
            content: "Are you sure you want to delete this portfolio?",
            onOk() {
                deletePortfolio(portfolioId)
                    .then((response) => {
                        setRefresh(!refresh);
                        navigate("/portfolio");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
    };

    return (
        <div>
            <h1>Portfolios</h1>
            <Row gutter={[8, 16]} justify="start">
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Card
                        hoverable
                        title="Create a Portfolio"
                        bordered={false}
                        className="square-card"
                        onClick={toggleCreatePortfolioModal}
                    >
                        <Meta description="Click here to create a new portfolio" />
                    </Card>
                </Col>
                {portfolios.map((portfolio) => (
                    <Col
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        key={portfolio.portfolio_id}
                    >
                        <Card
                            hoverable
                            title={portfolio.name}
                            bordered={false}
                            className="square-card"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    "/portfolio/" + portfolio.portfolio_id
                                );
                            }}
                            extra={
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // Handle delete portfolio logic here
                                        handleDeletePortfolio(
                                            portfolio.portfolio_id
                                        );
                                    }}
                                >
                                    <DeleteOutlined />
                                </span>
                            }
                        >
                            <Meta description={portfolio.description} />
                        </Card>
                    </Col>
                ))}
            </Row>
            <CreatePorfolioModal
                open={showCreatePortfolioModal}
                toggleCreatePortfolioModal={toggleCreatePortfolioModal}
            />
        </div>
    );
};

export default memo(PortfolioDashboardApp);
